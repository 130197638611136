<template>
  <div class="member-layout">


      <div class="login-btn-box">
<!--        <div @click="chkNaver('join')" class="btn btn-x-large mb-10 btn-sns-login sns-naver"><img src="@/assets/img/common/sns_naver_logo.png"> <span>간편 가입하기</span></div>-->
<!--        <div @click="chkFacebook('join')" class="btn btn-x-large mb-10 btn-sns-login sns-facebook"><img src="@/assets/img/common/sns_facebook_logo.png"> <span>간편 가입하기</span></div>-->
<!--        <div @click="chkKakao('join')" class="btn btn-x-large mb-10 btn-sns-login sns-kakao"><img src="@/assets/img/common/sns_kakao_symbol.svg"> 카카오로 3초 만에 가입하기</div>-->
        <div @click="joinKakao" class="btn btn-x-large mb-10 btn-sns-login sns-kakao"><img src="@/assets/img/common/sns_kakao_symbol.svg"> 카카오로 3초 만에 가입하기</div>
      </div>

    <div class="mt-15" @click="$router.push('/join/email')">
      <button class="btn btn-x-large color-1 btn-block btn-outline">이메일 회원가입</button>
    </div>

  </div>
</template>
<script>
import snsMixin from "@/mixins/snsMixin"
export default {
  name: "join",
  mixins: [snsMixin],
  data() {
    return {
    }
  },
  created() {
    const snsCode = this.$route.query.code
    const snsState = this.$route.query.state
    if (snsCode && snsState) this.kakaoSync(snsCode, snsState).then()
  },
  methods: {
    joinKakao() {
      location.href = 'https://kauth.kakao.com/oauth/authorize?response_type=code' +
        '&client_id=' + this.$kakaoId +
        '&redirect_uri=' + encodeURIComponent(this.$joinCallback) +
        '&state=join' +
        '&encode_state=true'
    },
  }
}
</script>
